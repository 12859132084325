<template>
  <div
    class="auth-layout-wrap"
    :style="{ backgroundImage: 'url(' + bgImage + ')' }"
  >
    <div class="auth-content">
      <div class="card o-hidden">
        <div class="row">
          <div class="col-md-12">
            <div class="p-4">
              <div class="auth-logo text-center mb-30">
                <img :src="logo" alt="" />
              </div>
              <h1 class="mb-3 text-18" >Welcome to ISA</h1>
              <span>After filling this form, you'll be able to connect to MyISA.</span>
              <hr/>
                <validation-observer ref="observer" v-slot="{ handleSubmit }" >
                  <b-form @submit.prevent="handleSubmit(apply)" autocomplete="off">     

                    <validation-provider
                      name="firstname"
                      :rules="{ required: true, required: false, min: 2, max: 250 }"
                      v-slot="validationContext"
                    >
                      <b-form-group :label="$t('Firstname') + ' ' + $t('(optionnal)')" class="text-12">
                        <b-form-input
                          class="form-control-rounded"
                          type="text"
                          v-model="firstname"
                          :placeholder="$t('Firstname')"                          
                          @keypress="makeLoginOnEnter"
                          aria-describedby="input-1-live-feedback"
                          :state="getValidationState(validationContext)"
                          id="invitation_confirm_firstname"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider
                      name="lastname"
                      :rules="{ required: true, required: false, min: 2, max: 250 }"
                      v-slot="validationContext"
                    >
                      <b-form-group :label="$t('Lastname') + ' ' + $t('(optionnal)')" class="text-12">
                        <b-form-input
                          class="form-control-rounded"
                          type="text"
                          v-model="lastname"
                          :placeholder="$t('Lastname')"                          
                          @keypress="makeLoginOnEnter"
                          aria-describedby="input-1-live-feedback"
                          id="invitation_confirm_lastname"
                          :state="getValidationState(validationContext)"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider name="language" :rules="{ required: true}" v-slot="validationContext">
                    <b-form-group label-cols-sm="3" :label="$t('Favorite Language')" label-align-sm="left" label-for="Language">
                      <b-form-select                
                        v-model="preferredLanguage"
                        :options="preferredLanguages"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-p2-live-feedback"
                        id="invitation_confirm_language"
                      ></b-form-select>              
                      <b-form-invalid-feedback
                          id="input-p2-live-feedback"
                        >{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                    </validation-provider>

                    <validation-provider
                      name="NewPassword"
                      :rules="{ required: true, required: true, min: 5, max: 250 }"
                      v-slot="validationContext"
                    >
                      <b-form-group :label="$t('New Password')" class="text-12">
                        <b-form-input
                          class="form-control-rounded password"
                          type="text"
                          autocomplete="off" readonly 
                          @focus="removeReadonly"
                          v-model="authorizePassword1"
                          placeholder="new password"                          
                          @keypress="makeLoginOnEnter"
                          aria-describedby="input-1-live-feedback"
                          id="invitation_confirm_password"
                          :state="getValidationState(validationContext)"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider
                      name="NewPasswordConfirmation"
                      rules="required|password:@NewPassword"
                      v-slot="validationContext"
                    >
                    <b-form-group :label="$t('New Password Confirmation')" class="text-12">
                      <b-form-input
                        class="form-control-rounded password"
                        type="text"
                        v-model="authorizePassword2"
                        placeholder="password confirmation"                                                
                        @keypress="makeLoginOnEnter"
                        aria-describedby="input-2-live-feedback"
                        id="invitation_confirm_passwordconfirm"
                        :state="getValidationState(validationContext)"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                    </validation-provider>
                   
                    <div class="input-group mb-3" style="margin-top: 20px; width: 100%">
                        <b-button
                          type="submit"
                          tag="button"
                          class="btn-rounded btn-block mt-2"
                          variant="primary mt-2"        
                          id="invitation_confirm_button"                                            
                        >
                          {{ $t('Accept Invitation') }}
                        </b-button>                      
                    </div>
                     
                  </b-form>
                 </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AuthService } from "@/services/auth.service";
import { API_URL } from '@/.env';
import { IMGService } from '@/services/img.service'
import { extend } from 'vee-validate';

extend('password', {
  params: ['target'],
  validate(value, { target }) {    
    return value === target;
  },
  message: 'Password confirmation does not match'
});


export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Forgot Password"
  },
  data() {
    return {
      bgImage: IMGService.bgImage,
      logo: IMGService.isalogo,      
      firstname: null,
      lastname: null,
      preferredLanguage: 'en',
      preferredLanguages: [
        { value: "en", text: "English" },
        { value: "fr", text: "French" },
        { value: "nl", text: "Dutch" },
        /*{ value: "zh", text: "Chinese" },                
        { value: "de", text: "German" },
        { value: "it", text: "Italian" },
        { value: "ja", text: "Japanese" },
        { value: "pt", text: "Portugese" },
        { value: "ru", text: "Russian" },
        { value: "sp", text: "Spanish" }*/
      ],
      authorizePassword1: null,
      authorizePassword2: null,
    };
  }, 
  methods: {
    removeReadonly(e) {
      e.target.removeAttribute('readonly');                
    },
    async onLoad() {       
      const token = this.$route.query ? this.$route.query.token : null;
      console.log(this.$route)
      console.log(this.$route.query)
      if(!token)
      {
        this.$bvToast.toast(this.$t('Noo valid token found on this page.'), {
            title: this.$t('Invitation Error'),
            variant: "warning",
            solid: true,
            duration: 5000
          });
          setTimeout(() => {          
            this.$router.push('/');
          }, 5000);                 
      }
      try
      {
        const result = await AuthService.getInvitationRequest(API_URL.url, token);
        
      } catch(err) {
        this.$bvToast.toast(this.$t('An error occured when processing you invitation. Please contact support.'), {
          title: this.$t('Invitation Accepted'),
          variant: "warning",
          solid: true,
          duration: 5000
        });
        setTimeout(() => {          
          this.$router.push('/');
        }, 5000);          
      }
      
        
    },
    makeLoginOnEnter(e) {      
      if (e.keyCode === 13) {
        this.apply();
      }
    },
    async apply() {
      const token = this.$route.query ? this.$route.query.token : null;
      console.log(this.$route)
      console.log(this.$route.query)
      if(!token)
        return this.$router.push('/');
      let result = null;
      try
      {
        result = await AuthService.processInvitationRequest(API_URL.url, token, this.firstname || "", this.lastname || "", this.authorizePassword1, this.preferredLanguage);
      } catch(err) {
        result = err;
      }
      console.log(result)
      
      if(result.status === 200) {        
        this.$bvToast.toast(this.$t('New password has been set. Please sign in.'), {
          title: `Invitation Accepted`,
          variant: "success",
          solid: true,
          duration: 5000,
        });
        setTimeout(() => {          
          this.$router.push('/app/sessions/signIn'); 
        }, 5000);
            
      } else if (result.status == 409 && result.code == 8304) {        
        this.$bvToast.toast(this.$t('An error occured when processing you invitation. Please contact support.'), {
          title: this.$t('Invitation Accepted'),
          variant: "warning",
          solid: true,
          duration: 5000
        });
      }
    },
    getValidationState({ dirty, validated, valid = null }) {      
      return dirty || validated ? valid : null;
    },
  },
  mounted() {
    this.onLoad();
  }
};
</script>

<style scoped>
  .password {
      -webkit-text-security:disc;
      font-family: text-security-disc;
  }
</style>
